<template>
  <form class="form-cms mt-1">
    <div class="form-control">
      <label for="characteristics-ingredient">Característica:</label>
      <textarea
        rows="3"
        name="characteristics-ingredient"
        placeholder="Característica"
      />
    </div>
    <div class="buttons mt-1">
      <button class="btn btn-primary">
        <i class="fas fa-plus"></i> Agregar
      </button>
    </div>
  </form>
</template>
